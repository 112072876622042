import {
  REMOVE_ITEM,
  ADD_ITEM,
  SET_CART,
  UPDATE_ITEM,
  EMPTY_CART,
  UPDATE_CART_LINENUMBERS,
} from '../actions/actionTypes';

const initialState = {
  cart: [], // current cart for the authenticated user
};

export function cartReducer(state = initialState, action) {
  const stateCopy = { ...state };
  switch (action.type) {
    case SET_CART:
      return { ...stateCopy, cart: [...action.cart] };
    case ADD_ITEM:
      let addToCart = [...state.cart, { ...action.item }];
      // re-arrange items in cart - group similar items...
      addToCart = resortCart(addToCart);
      return { ...stateCopy, cart: [...addToCart] };
    case UPDATE_ITEM:
      let updatedCart = [
        ...state.cart.filter(
          (x) => x.artikel.artikelID !== action.item.artikel.artikelID,
        ),
      ];
      updatedCart.push(action.item);
      // re-arrange items in cart - group similar items...
      updatedCart = resortCart(updatedCart);
      return { ...stateCopy, cart: [...updatedCart] };
    case REMOVE_ITEM:
      return {
        ...stateCopy,
        cart: [...state.cart.filter((x) => x.artikel.artikelID !== action.id)],
      };
    case EMPTY_CART:
      return { ...stateCopy, cart: [] };
    case UPDATE_CART_LINENUMBERS:
      // first of all, take copy of current cart, check if there is payload, and start comparing...
      let newCart = [...stateCopy.cart]; 
      if(action.payload && Object.keys(action.payload).length>0){
        Object.keys(action.payload).forEach(articleNr => {
          let match = newCart.find(x => (x.article.articleNr.trim() === articleNr && x.linenr === -1));
          if(match) match.linenr = action.payload[articleNr];
        });
      }
      return { ...stateCopy, cart: [...newCart]};
    default:
      return state;
  }
}

/**
 * Re-order the cart, so that similar items are grouped together.
 */
const resortCart = (cart) => {
  if(!cart) return cart;
  const newCart = [];
  while(cart.length > 0) {
    let item = cart.shift();
    if(item){
      let idx1 = newCart.findLastIndex(elem => (elem.linenr === item.linenr));
      if(idx1 >= 0 ){
        newCart.splice((idx1+1),0,item);
      }else{
        let idx2 = newCart.findLastIndex(elem => (elem.artikel.artikelID === item.artikel.artikelID));
        if(idx2 >= 0 ){
          newCart.splice((idx2+1),0,item);
        }else{
          // add in the back...
          newCart.push(item);
        }
      }
    }
  }
  return newCart;
}