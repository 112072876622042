import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Add an item to the current user's cart
 * @param {Object} params
 * @return {Object} { artikel, amount, amount2, amount3 }
 */
const addItemToCart = (params) => {
  return axios.post(`${API_URL}/cart`, params).then((res) => {
    return res.data;
  });
};

/**
 * Fetch the current cart from the server
 * All content of a cart is also stored on the database. Not only on the frontend.
 * @return {Array} cart
 */
const getCurrentCart = () => {
  return axios.get(`${API_URL}/cart`).then((res) => {
    return res.data;
  });
};

/**
 * Remove the item with the given id from the current user's cart
 * @param {String} itemId
 * @return {String} item id
 */
const removeItemFromCart = (itemId) => {
  return axios.delete(`${API_URL}/cart?id=${itemId}`).then((res) => {
    return res;
  });
};

/**
 * Save the configured weeks from the promo screen
 * @param {Number} week1
 * @param {Number} week2
 * @param {Number} week3
 * @return {Object} ???
 */
const saveWeeks = (week1, week2, week3) => {
  return axios
    .post(`${API_URL}/order/weeks`, { week1, week2, week3 })
    .then((res) => {
      return res;
    });
};

/**
 * Fetch the current configured weeks for this user
 * @return {Object} { week1, week2, week3 }
 */
const getWeeks = () => {
  return axios.get(`${API_URL}/order/weeks`).then((res) => {
    return res;
  });
};

/**
 * get the current order id for web orders - if any active...
 * @returns 
 */
const getCurrentWeborderId = () => {
  return axios.get(`${API_URL}/order/current`).then((res) => {
    return res.data;
  });
}

/**
 * Filter out articles not allowed to purchase
 * @return {res}
 */
const getUitsluitingenVoorKlant = () => {
  return axios.get(`${API_URL}/uitsluitingen`).then(
    (res) => {
      return res;
    },
  );
};

/**
 * Call to delete order in ERP
 * @return {res}
 */
const verwijderWebOrder = (orderId) => {
  console.log("Cancelling Open Order for Web Order Id", orderId);
  return axios.get(`${API_URL}/verwijderweborder?orderid=${orderId}`).then(
    (res) => {
      return res;
    },
  );
};

export default {
  addItemToCart,
  getCurrentCart,
  removeItemFromCart,
  saveWeeks,
  getWeeks,
  getUitsluitingenVoorKlant,
  verwijderWebOrder,
  getCurrentWeborderId,
};
