import { store } from '../index';

/**
 * Format article data to minimal format used in frontend store
 * @param {Object} data
 */
export const formatCartArticle = (data) => {
  const { artikel, hoeveelheid1, hoeveelheid2, hoeveelheid3, free, added, linenr, weborderID } = data;
  const response = {
    hoeveelheid1,
    hoeveelheid2,
    hoeveelheid3,
    free,
    added,
    linenr,
    weborderID,
    artikel: {
      id: artikel.id,
      artikelID: artikel.artikelID,
      basisprijs: artikel.basisprijs,
      btw: artikel.btw,
      categorieID: artikel.categorieID,
      EAN: artikel.EAN,
      foto: artikel.foto,
      omschrijving_nl: artikel.omschrijving_nl,
      omschrijving_fr: artikel.omschrijving_fr,
      verkoopprijs: artikel.verkoopprijs,
      merkID: artikel.merkID,
    },
  };

  return response;
};

/**
 * Calculate the total sale amount of the current cart
 */
export const calculateCartTotal = () => {
  const cart = store.getState().cartModule.cart;
  return cart.reduce((a, b) => a + b.hoeveelheid1 * b.artikel.verkoopprijs, 0);
};

/**
 * Calculate the total sale amount of the current cart
 */
export const calculateCartItemDiscounts = (cartItem, user) => {  
  // CartItem object is different as it may originate from cart or catalog
  if(!cartItem.artikel) {
    cartItem.artikel = cartItem;
  }
  let basisPrijs = null;
  let merkID = null;
  if(cartItem && cartItem.artikel && cartItem.artikel.basisprijs) {
    basisPrijs = cartItem.artikel.basisprijs;
    merkID = cartItem.artikel.merkID;
  }
  let klantID = null;
  if(user && user.userInfo && user.userInfo.klantID) {
    klantID = user.userInfo.klantID;
  }

  let merkDiscount = null;
  if(merkID && klantID && user.merkkorting && user.merkkorting.length > 0) {
    user.merkkorting.forEach(merkkorting => {
      if(merkkorting.klantID === klantID && merkkorting.merkID === merkID) {
        merkDiscount = merkkorting.korting;
      }
    })
  }

  let klantKorting = null;
  if(user && user.klantkorting && user.klantkorting.korting_1) {
    if(klantKorting==null) klantKorting = 0;
    if(typeof(user.klantkorting.korting_1) === "string") user.klantkorting.korting_1 = parseFloat(user.klantkorting.korting_1);
    klantKorting += user.klantkorting.korting_1;
  }
  if(user && user.klantkorting && user.klantkorting.korting_2) { 
    if(klantKorting==null) klantKorting = 0;
    if(typeof(user.klantkorting.korting_2) === "string") user.klantkorting.korting_2 = parseFloat(user.klantkorting.korting_2);
    klantKorting += user.klantkorting.korting_2;
  }
  if(user && user.klantkorting && user.klantkorting.korting_3) { 
    if(klantKorting==null) klantKorting = 0;
    if(typeof(user.klantkorting.korting_3) === "string") user.klantkorting.korting_3 = parseFloat(user.klantkorting.korting_3);
    klantKorting += user.klantkorting.korting_3;
  }
  if(user && user.klantkorting && user.klantkorting.korting_4) { 
    if(klantKorting==null) klantKorting = 0;
    if(typeof(user.klantkorting.korting_4) === "string") user.klantkorting.korting_4 = parseFloat(user.klantkorting.korting_4);
    klantKorting += user.klantkorting.korting_4;
  }

  if(klantKorting) {
    basisPrijs = (basisPrijs * ( 100 - klantKorting )) / 100;
  }

  if(merkDiscount) {
    basisPrijs = (basisPrijs * ( 100 - merkDiscount )) / 100;
  }
  
  // Voorstel van peter voor als deze niet goed zou werken
  // ( Basis Prijs * (1 - klantenkortingperc/100)  ) *  ( 1 - merkenkortingperc/100 ) )

  if(basisPrijs==null) return "";
  return basisPrijs.toFixed(2) 
};

/**
 * Check if two values (strings/objects are equal)
 * @param {*} valueOne 
 * @param {*} valueTwo 
 * @param {boolean} ignoreCase
 * @returns true/false
 */
export const equalsValue = (valueOne, valueTwo, ignoreCase=false) => {
  if(valueOne != null && valueTwo != null && valueOne && valueTwo) {
    if(ignoreCase) return (''+valueOne).trim().toLowerCase() === (''+valueTwo).trim().toLowerCase();
    else return (''+valueOne).trim() === (''+valueTwo).trim();
  }
  return false;
};


/**
 * Calculate the (new) facturatie prijs based on the
 * prices that were received after the promotional calculation
 * 
 * @param {Object} cartItem the article item with details
 * @param {Map} facturatiePrijzen list of adjusted prices
 * @returns facturatiePrijs
 */
export const getFacuratiePrijs = (cartItem, facturatiePrijzen) => {
  if(!cartItem || !cartItem.artikel) return '';

  const artikelId = cartItem.artikel.artikelID || null;
  let facturatiePrijs = cartItem.artikel.basisprijs || cartItem.artikel.verkoopprijs || '';
  if(artikelId && facturatiePrijzen && facturatiePrijzen.length > 0) {
    facturatiePrijzen.forEach((facPrijs) => {
      if(equalsValue(facPrijs.articleNr,artikelId,true) && 
          ( (cartItem.added === facPrijs.extraAdded ) ||
            (cartItem.free === facPrijs.isGratis ) )
        ){
          facturatiePrijs = facPrijs.newPrice;
      };
    })
  };
  //console.log("Calculated Facturatie prijs for '"+artikelId+"' = ", facturatiePrijs);
  return facturatiePrijs;
};

/**
 * Get the total count of artikel items ordered 
 * from cart item
 * @param {Object} cartItem 
 * @returns the total item count 
 */
export const countItemsPerArticle = (cartItem) => {
  let count = 0;
  if(cartItem){
    if(cartItem.hoeveelheid1)
      count += cartItem.hoeveelheid1;
    if(cartItem.hoeveelheid2)
      count += cartItem.hoeveelheid2;
    if(cartItem.hoeveelheid3)
      count += cartItem.hoeveelheid3;
  }
  return count;
};

/**
 * Pad prices with 2 decimals
 * @param {*} price 
 * @returns padded with 2 decimals
 */
export const padPricing = (price) => {
  if (price !== null && price !== '' && !isNaN(price)) {
    price = price.toFixed(2)
  }
  return price;
}

/**
 * Calculate next line nr, based on current cart, current given line nr and
 * conditions around it...
 * @param {*} currentCart 
 * @param {*} linenr 
 * @param {*} LOCAL_ONLY 
 */
export const calculateLineNr = (currentCart=null, linenr=-1, LOCAL_ONLY=false) => {
  let newLineNr = 1;
  if(currentCart && currentCart.length>0){
    currentCart.map(x => { if(x.linenr >= newLineNr) (newLineNr=x.linenr+1); return x.linenr; });
    /*
    cartElem= {
      added,
      artikel,
      free,
      hoeveelheid1, hoeveelheid2, hoeveelheid3,
      linenr,
      weborderID,
    }
    */
  }
  // only return new line nr if it was not provided...
  if(!linenr || linenr <= 0) return newLineNr;
  return linenr;
};