import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Try to authenticate a webshop user
 * @param {Object} userInfo
 * @return {Object} token/payload
 */
const login = (userInfo) => {
  return axios.post(`${API_URL}/login`, userInfo).then((res) => {
    const { token, payload } = res.data;
    return { token, payload };
  });
};

/**
 * Refetch the login data with the given token
 * @param {String} token
 * @return {Object} token/payload
 */
const getAuthentication = (token) => {
  return axios.get(`${API_URL}/verify`, token).then((res) => {
    const { payload, token } = res.data;
    return { token, payload };
  });
};

/**
 * Try to authenticate an admin user for the CMS
 * @param {Object} adminInfo
 * @return {Object} token/payload
 */
const loginAdmin = (adminInfo) => {
  return axios.post(`${API_URL}/login/admin`, adminInfo).then((res) => {
    const { token, payload } = res.data;
    return { token, payload };
  });
};

/**
 * Refetch the login data from an admin account with the given token
 * @param {String} token
 * @return {Object} token/payload
 */
const verifyAdminAuthentication = (token) => {
  return axios.get(`${API_URL}/verifyadmin`, token).then((res) => {
    const { payload, token } = res.data;
    return { token, payload };
  });
};

export default {
  login,
  getAuthentication,
  loginAdmin,
  verifyAdminAuthentication,
};
