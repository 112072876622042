import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import action_fr from './translations/fr/action.json';
import action_nl from './translations/nl/action.json';
import backorder_fr from './translations/fr/backorder.json';
import backorder_nl from './translations/nl/backorder.json';
import basic_fr from './translations/fr/basic.json';
import basic_nl from './translations/nl/basic.json';
import checkout_fr from './translations/fr/checkout.json';
import checkout_nl from './translations/nl/checkout.json';
import cms_fr from './translations/fr/cms.json';
import cms_nl from './translations/nl/cms.json';
import herstelling_fr from './translations/fr/herstelling.json';
import herstelling_nl from './translations/nl/herstelling.json';
import info_fr from './translations/fr/info.json';
import info_nl from './translations/nl/info.json';
import notifications_fr from './translations/fr/notifications.json';
import notifications_nl from './translations/nl/notifications.json';
import navigation_fr from './translations/fr/navigation.json';
import navigation_nl from './translations/nl/navigation.json';

/**
 * We use i18next to translate the content on the webshop/cms.
 * The translations are stored inside this project and must be updated in the corresponding files
 * Currently we only support dutch and french
 * We split the translations in seperate modules (basic/notifications/navigation/cms)
 */
i18n.use(initReactI18next).init({
  lng: 'nl',
  resources: {
    nl: {
      action: action_nl,
      backorder: backorder_nl,
      basic: basic_nl,
      checkout: checkout_nl,
      cms: cms_nl,
      herstelling: herstelling_nl,
      info: info_nl,
      notifications: notifications_nl,
      navigation: navigation_nl,
    },
    fr: {
      action: action_fr,
      backorder: backorder_fr,
      basic: basic_fr,
      checkout: checkout_fr,
      cms: cms_fr,
      herstelling: herstelling_fr,
      info: info_fr,
      notifications: notifications_fr,
      navigation: navigation_fr,
    },
  },
});

export default i18n;
