import { ADD_ARTICLE, PROMOS_ADD, ARTICLES_LOADING, SET_KENMERKEN, SET_PARAMS_FOR_GET_ARTICLES, STORE_UITSLUITINGEN, PROMOS_LOADING } from '../actions/actionTypes';

const initialState = {
  artikels: null, // fetched articles for products/pricelist page
  articlesLoading: false, // loading is currently being done...
  promos: null, // fetched promos for promotions page
  kenmerken: [], // for filter on prijslijst
  paramsForGetArticles: null, // for selected checkboxes in filter
  uitsluitingenVoorKlant: [], // list of items excluded for this consumer
  promoLoading: false, // loading of promotions
};

export function articleReducer(state = initialState, action) {
  // const stateCopy = { ...state };
  switch (action.type) {
    case ADD_ARTICLE:
      if (action.page !== 0) {
        return Object.assign({}, state, {
          artikels: [...state.artikels, ...action.response],
        });
      } else {
        return Object.assign({}, state, {
          artikels: action.response,
        });
      }
    case PROMOS_ADD:
      return Object.assign({}, state, {
        promos: action.response,
      });
    case SET_KENMERKEN:
      return Object.assign({}, state, {
        kenmerken: action.payload,
      });
    case SET_PARAMS_FOR_GET_ARTICLES:
      return Object.assign({}, state, {
        paramsForGetArticles: action.params,
      });
    case STORE_UITSLUITINGEN:
      return Object.assign({}, state, {
        uitsluitingenVoorKlant: action.listUitsluitingen,
      });
    case  ARTICLES_LOADING: 
      return  Object.assign({}, state, {
        articlesLoading: action.status,
      });
    case  PROMOS_LOADING: 
      return  Object.assign({}, state, {
        promoLoading: action.status,
      });
    default:
      return state;
  }
}