import './index.css';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/index';
import './i18n';

//import Router from './router/Router';
const Router = lazy(()=>import('./router/Router'));
export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={
        <div className="landing_wrap">
        <div className="landing_page">
          <div className="landing_page_center">
            <div className="landing_page_language">
              <table className="">
                <tbody>
                  <tr>
                    <td className='landing_page_lang_option'>
                      <div className='landing_page_wait_text'>Loading Website ... please wait ...</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='landing_page_logo_address'>
                <span><img className='logo_space'  src="elektron_logo.png" alt="Elektron Logo"/> </span>
                <br/>
                <span>Elektron B.V. - Peepolderlaan 3, 2910 Essen</span>
                <br/>
                <span>Tel: 03/ 670 03 30 - BTW: BE 0417.072.383</span>
            </div>
          </div>
        </div>
        </div>
      }>
      <Router />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);
