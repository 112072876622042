import { call, put, takeLatest, select
   } from 'redux-saga/effects';
import {
  USER_REQ,
  ADD_USER,
  INFO_REQ,
  ADD_INFO,
  HERSTELLING_REQ,
  ADD_HERSTELLING,
  BACKORDER_REQ,
  ADD_BACKORDER,
  ACTIES_REQ,
  ADD_ACTIES,
  ART_PROMO_REQ,
  ADD_ART_PROMO,
  ART_PROMO_LOADING,
  ADD_SORTED_ART_PROMO,
  ART_PROMO_ERROR,
  EXCEL_LINK,
  CREATE_EXCEL,
  ACTIONS_LOADING,
  DISABLE_EXCEL_BUTTON,
  RESET_EXCEL_DOWNLOAD_LINK,
  DOCUMENTS_GENERATING,
  SELECTED_CONSUMERACTIES,
  ARTICLE_ACTIONS_REQ,
} from '../actions/actionTypes';
import UserService from '../../services/UserService';

export const userSagas = [
  takeLatest(USER_REQ, getUserInfo),
  takeLatest(INFO_REQ, getVoorwaarden),
  takeLatest(HERSTELLING_REQ, getHerstellingen),
  takeLatest(BACKORDER_REQ, getBackorders),
  takeLatest(ACTIES_REQ, getConsumentActies),
  takeLatest(ART_PROMO_REQ, getArtikelPromoties),
  takeLatest(CREATE_EXCEL, createExcel),
  takeLatest(ARTICLE_ACTIONS_REQ, getConsumentActiefForArticle),
];

/**
 * Fetch the user data related to the authenticated user
 * Save data to the store
 */
function* getUserInfo() {
  try {
    const response = yield call(UserService.getUserInfo);
    yield put({ type: ADD_USER, response });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch the general & sales conditions for the webshop
 * Save data to the store
 */
function* getVoorwaarden() {
  try {
    const response = yield call(UserService.getVoorwaarden);
    yield put({ type: ADD_INFO, response });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch the repairs that are linked to the authenticated user
 * Save the data to the store
 */
function* getHerstellingen() {
  try {
    let response = yield call(UserService.getHerstellingen);

    if(response && response.length > 0) {
      response.forEach(singleResponse => {
        if(singleResponse.artikel !== null && singleResponse.artikel) {
          singleResponse.omschrijving_nl = singleResponse.artikel.omschrijving_nl;
          singleResponse.omschrijving_fr = singleResponse.artikel.omschrijving_fr;
        }
      })
    }

    yield put({ type: ADD_HERSTELLING, response });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch the backorders that are linked to the authenticated user
 */
function* getBackorders() {
  try {
    const response = yield call(UserService.getBackorders);
    yield put({ type: ADD_BACKORDER, response });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch actions that are available for the authenticated user
 * Save the data to the store
 */
function* getConsumentActies({ payload }) {
  try {
    yield put({ type: ACTIONS_LOADING, response: true });
    const response = yield call(UserService.getConsumentenActies,payload.limit, payload.page);
    //response = {acties: [], total: 0, page:0, size:0 }
    yield put({ type: ADD_ACTIES, response });
    yield put({ type: ACTIONS_LOADING, response: false });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch the given consumer actions for given article ID
 * @param {*} payload = articleId 
 */
function* getConsumentActiefForArticle({payload}){
  try {
    yield put({ type: SELECTED_CONSUMERACTIES, response: null });
    yield put({ type: ACTIONS_LOADING, response: true });
    const response = yield call(UserService.getConsumentenActiesForArticle,payload);
    //response = {acties: [], total: 0, page:0, size:0 }
    yield put({ type: SELECTED_CONSUMERACTIES, response });
    yield put({ type: ACTIONS_LOADING, response: false });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch the articles with promotions that are valid for the authenticated user
 * Save the data to the store
 */
function* getArtikelPromoties() {
  try {
    yield put({ type: ART_PROMO_LOADING });
    const state = yield select(); // check reload from state...
    let alreadyReloaded = state ? state.userModule.articlePromosReLoaded : false;
    const response = yield call(UserService.getArtikelPromoties, !alreadyReloaded);
    const sortedByPromo = yield call(
      groupBy,
      response,
      (promo) => promo.promotie,
    );
    yield put({ type: ADD_ART_PROMO, response });
    yield put({
      type: ADD_SORTED_ART_PROMO,
      payload: sortedByPromo,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: ART_PROMO_ERROR });
  }
}

function groupBy(list, keyGetter) {
  const map = {};
  list.forEach((item) => {
    const key = keyGetter(item).trim();
    const collection = map[key];
    if (!collection) {
      map[key] = [item];
    } else {
      collection.push(item);
    }
  });
  return map;
}


/**
 * Create Excel file in the backend
 */
function* createExcel(payload) {
  try {
    yield put({ type: DISABLE_EXCEL_BUTTON, payload: true }); 
    const excelLinkPayload = yield call(UserService.createExcel, payload.payload);
    yield put({ type: EXCEL_LINK, payload: excelLinkPayload });  
    yield put({ type: RESET_EXCEL_DOWNLOAD_LINK}); 
    yield put({ type: DISABLE_EXCEL_BUTTON, payload: false }); 
    yield put({ type: DOCUMENTS_GENERATING, payload: false }); 
    const excelMsg = document.getElementById('showExcelCreationMessage');
    if(excelMsg)
      excelMsg.innerHTML = "";
  } 
  catch (e) {
    //console.log(e);
    
  }
}
