import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import authService from '../../services/AuthService';
import axios from 'axios';
import {
  VERIFY_AUTH,
  ADD_AUTH,
  REMOVE_AUTH,
  AUTH_REQ,
  LOGOUT_REQ,
  REMOVE_USER,
  ADMIN_REQ,
  ADMIN_AUTH,
  ADD_NOTIFICATION,
  VERIFY_ADMIN,
  SET_CART,
  AUTH_LOADING,
  AUTH_ERROR,
  CHANGE_LANGUAGE,
} from '../actions/actionTypes';
import cartService from '../../services/CartService';
import { formatCartArticle } from '../../helpers/cartHelper';

export const authSagas = [
  takeLatest(AUTH_REQ, loginUser),
  takeLatest(LOGOUT_REQ, logoutUser),
  takeLatest(VERIFY_AUTH, verifyAuthentication),
  takeLatest(ADMIN_REQ, loginAdmin),
  takeLatest(VERIFY_ADMIN, verifyAdminAuth),
];

/**
 * Try to login an admin for the CMS
 * If succesfull set the default header for calls/save data to localstorage
 * Redirects to /cms
 * @param {Object} payload
 */
function* loginAdmin(action) {
  try {
    const response = yield call(authService.loginAdmin, action.payload);
    yield put({ type: ADMIN_AUTH, response });
    axios.defaults.headers.common.Authorization = `Bearer ${response.token}`;
    localStorage.removeItem('ELEKTRON_TOKEN');
    localStorage.setItem('CMS_TOKEN', response.token);
    yield put({
      type: ADD_NOTIFICATION,
      notification: {
        title: 'auth_success',
        message: 'admin_auth_success',
        type: 'success',
      },
    });
    yield put(push('/cms'));
  } catch (e) {
    yield put({
      type: ADD_NOTIFICATION,
      notification: {
        title: 'Login Problem',
        message: 'Login Failed',
        type: 'error',
      },
    });
    localStorage.removeItem('CMS_TOKEN');
    delete axios.defaults.headers.common.Authorization;
    yield put({ type: REMOVE_AUTH, message: e.message });
  }
}

/**
 * Try to login a webshop user
 * If succesfull set the default header for calls/save data to localstorage
 * Fetches the current cart of the logged in user
 * Redirects to the webshop homepage
 * @param {Object} payload
 */
function* loginUser(action) {
  try {
    // Set auth loading state
    yield put({ type: AUTH_LOADING, loading: true });
    // Try to login on backend
    const response = yield call(authService.login, action.payload);
    // If successful, save auth data to store
    yield put({ type: ADD_AUTH, response });
    // Set axios headers
    axios.defaults.headers.common.Authorization = `Bearer ${response.token}`;
    // Save token in localstorage
    localStorage.setItem('ELEKTRON_TOKEN', response.token);
    // Set preferred language
    const languageCode = (response && response.payload && response.payload.languageCode) ? response.payload.languageCode : 1;
    let lng = (languageCode === "2" || languageCode === 2)? 'fr' : 'nl';
    // fix language to preferences
    localStorage.setItem('ELEKTRON_LNG', lng);
    yield put({ type: CHANGE_LANGUAGE, language: lng });
    // get cart
    const cart = yield call(cartService.getCurrentCart);
    const currentCart = cart.map((x) => formatCartArticle(x));
    yield put({ type: SET_CART, cart: currentCart });
    yield put(push('/webshop/nieuws'));
    yield put({ type: CHANGE_LANGUAGE, language: lng });
  } catch (e) {
    yield put({
      type: ADD_NOTIFICATION,
      notification: {
        title: 'Login Problem',
        message: 'Login Failed',
        type: 'error',
      },
    });
    yield put({ type: AUTH_LOADING, loading: false });
    yield put({ type: AUTH_ERROR, error: { message: 'Verkeerde login!' } });
    localStorage.removeItem('ELEKTRON_TOKEN');
    delete axios.defaults.headers.common.Authorization;
    yield put({ type: REMOVE_AUTH, message: e.message });
  }
}

/**
 * Logout a webshop user
 * Removes default header and localStorage data
 * Redirects to homepage
 */
function* logoutUser() {
  // Remove tokens from localStorage
  localStorage.removeItem('ELEKTRON_TOKEN');
  localStorage.removeItem('CMS_TOKEN');
  // Remove headers
  delete axios.defaults.headers.common.Authorization;
  // Remove auth data from store
  yield put({ type: REMOVE_AUTH });
  yield put({ type: REMOVE_USER });
  // Redirect to startpage
  yield put(push('/1'));
}

/**
 * Refetch authentication data for a webshop user
 * Only is called when a saved token is found in the localStorage
 * @param {Object} payload
 */
function* verifyAuthentication({ payload }) {
  try {
    const response = yield call(authService.getAuthentication, payload.token);
    yield put({ type: ADD_AUTH, response });
    axios.defaults.headers.common.Authorization = `Bearer ${response.token}`;
    localStorage.setItem('ELEKTRON_TOKEN', response.token);
    const cart = yield call(cartService.getCurrentCart);
    const currentCart = cart.map((x) => formatCartArticle(x));
    yield put({ type: SET_CART, cart: currentCart });
  } catch (e) {
    localStorage.removeItem('ELEKTRON_TOKEN');
    delete axios.defaults.headers.common.Authorization;
    yield put({ type: REMOVE_AUTH, message: e.message });
  }
}

/**
 * Refetch authentication data for a CMS user
 * Only is called when a saved token is found in the localStorage
 * @param {Object} payload
 */
function* verifyAdminAuth(action) {
  try {
    const response = yield call(
      authService.verifyAdminAuthentication,
      action.payload,
    );
    yield put({ type: ADMIN_AUTH, response });
    axios.defaults.headers.common.Authorization = `Bearer ${response.token}`;
    localStorage.setItem('CMS_TOKEN', response.token);
    yield put(push(action.payload.url));
    // yield put(push('/cms'));
  } catch (e) {
    localStorage.removeItem('CMS_TOKEN');
    delete axios.defaults.headers.common.Authorization;
    yield put({ type: REMOVE_AUTH, message: e.message });
  }
}
