import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;




/**
 * Fetch the items that should be displayed on the NORMAL website's menu
 * @return {Array} menu items
 */
const getMenuItems = () => {
  return axios.get(`${API_URL}/menu`).then((res) => {
    return res.data;
  });
};

/**
 * Fetch all the pages for the NORMAL website
 * Each page is plain html data
 * @return {Array} pages
 */
const getPages = () => {
  return axios.get(`${API_URL}/pages`).then((res) => {
    return res.data;
  });
};

/**
 * Update the content of the page matching the given id
 * @param {Object} { id, contentNL, contentFR }
 */
const savePage = ({ id, contentNL, contentFR }) => {
  return axios
    .post(`${API_URL}/pages`, { id, contentNL, contentFR })
    .then((res) => {
      return res;
    });
};

/**
 * Change the visiblity of a page
 * @param {Object} payload
 */
const updatePageVisibility = (payload) => {
  return axios.post(`${API_URL}/menu/update`, payload).then((res) => {
    return res;
  });
};

/**
 * Delete page (and corresponding menu item) from the normal website
 * @param {Object} { id, menuid }
 */
const deletePage = ({ id, menuid }) => {
  return axios
    .delete(`${API_URL}/pages`, { data: { id, menuid } })
    .then((res) => {
      return res;
    });
};

/**
 * Create a new page (and menu item)
 * @param {Object} payload
 */
const createPage = (payload) => {
  return axios.post(`${API_URL}/pages/new`, payload).then((res) => {
    return res;
  });
};

/**
 * Get path from which to download images / pdf / logos
 * @param {Object} payload
 */
const getFilePathToResources = () => {
  return axios.get(`${API_URL}/getfilepathtoresources`).then((res) => {
    return res.data;
  });
};

export default {
  getMenuItems,
  getPages,
  savePage,
  updatePageVisibility,
  deletePage,
  createPage,
  getFilePathToResources,
};
