import { all } from 'redux-saga/effects';
import { authSagas } from './authSaga';
import { userSagas } from './userSaga';
import { siteSagas } from './siteSaga';
import { articleSagas } from './articleSaga';
import { cartSagas } from './cartSaga';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...userSagas,
    ...siteSagas,
    ...articleSagas,
    ...cartSagas,
  ]);
}
