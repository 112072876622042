// Auth actiontypes
export const AUTH_REQ = 'AUTH_REQ';
export const ADD_AUTH = 'ADD_AUTH';
export const LOGOUT_REQ = 'LOGOUT_REQ';
export const REMOVE_AUTH = 'REMOVE_AUTH';
export const VERIFY_AUTH = 'VERIFY_AUTH';
export const ADMIN_REQ = 'ADMIN_REQ';
export const ADMIN_AUTH = 'ADMIN_AUTH';
export const VERIFY_ADMIN = 'VERIFY_ADMIN';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';

// User actiontypes
export const USER_REQ = 'USER_REQ';
export const ADD_USER = 'ADD_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const INFO_REQ = 'INFO_REQ';
export const ADD_INFO = 'ADD_INFO';
export const HERSTELLING_REQ = 'HERSTELLING_REQ';
export const ADD_HERSTELLING = 'ADD_HERSTELLING';
export const BACKORDER_REQ = 'BACKORDER_REQ';
export const ADD_BACKORDER = 'ADD_BACKORDER';
export const ACTIES_REQ = 'ACTIES_REQ';
export const ADD_ACTIES = 'ADD_ACTIES';
export const ART_PROMO_REQ = 'ART_PROMO_REQ';
export const ADD_ART_PROMO = 'ADD_ART_PROMO';
export const ART_PROMO_LOADING = 'ART_PROMO_LOADING';
export const ADD_SORTED_ART_PROMO = 'ADD_SORTED_ART_PROMO';
export const ART_PROMO_ERROR = 'ART_PROMO_ERROR';
export const ACTIONS_LOADING = 'ACTIONS_LOADING';
export const ARTICLE_ACTIONS_REQ = 'ARTICLE_ACTIONS_REQ';
export const SELECTED_CONSUMERACTIES = 'SELECTED_CONSUMERACTIES';

// Site actiontypes
export const MENU_REQ = 'MENU_REQ';
export const ADD_MENU = 'ADD_MENU';
export const PAGE_REQ = 'PAGE_REQ';
export const ADD_PAGE = 'ADD_PAGE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_VISIBILITY = 'CHANGE_VISIBILITY';
export const SAVE_CONTENT = 'SAVE_CONTENT';
export const DELETE_PAGE = 'DELETE_PAGE';
export const CREATE_PAGE = 'CREATE_PAGE';
export const SET_FILE_PATH_TO_RESOURCES = 'SET_FILE_PATH_TO_RESOURCES';
export const GET_FILE_PATH_TO_RESOURCES = 'GET_FILE_PATH_TO_RESOURCES';
export const SET_CHECKOUT_FORM_DATA = 'SET_CHECKOUT_FORM_DATA';
export const SET_CHECKOUT_FORM_PDF = 'SET_CHECKOUT_FORM_PDF';
export const RESET_CHECKOUT_FORM_PDF = 'RESET_CHECKOUT_FORM_PDF';
// Artikel actiontypes
export const ARTICLE_REQ = 'ARTICLE_REQ';
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const PROMOS_REQ = 'PROMOS_REQ';
export const PROMOS_ADD = 'PROMOS_ADD';
export const ARTICLES_LOADING = 'ARTICLES_LOADING';
export const SET_KENMERKEN = 'SET_KENMERKEN';
export const GET_KENMERKEN = 'GET_KENMERKEN';
export const SET_PARAMS_FOR_GET_ARTICLES = 'SET_PARAMS_FOR_GET_ARTICLES';
export const STORE_UITSLUITINGEN = 'STORE_UITSLUITINGEN';
export const PROMOS_LOADING = 'PROMOS_LOADING';

// Cart actiontypes
export const ADD_CART = 'ADD_CART'; //for server
export const ADD_ITEM = 'ADD_ITEM'; //for redux
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const REMOVE_CART = 'REMOVE_CART'; //for server
export const REMOVE_ITEM = 'REMOVE_ITEM'; //for redux
export const SET_CART = 'SET_CART';
export const GET_CURRENT_CART= 'GET_CURRENT_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const UPDATE_CART_LINENUMBERS = 'UPDATE_CART_LINENUMBERS';
export const UPDATE_CART = 'UPDATE_CART';

// Order/Cart action types
export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const COMPLETE_ORDER_CONSUMER = 'COMPLETE_ORDER_CONSUMER';
export const GET_UITSLUITINGEN_VOOR_KLANT = 'GET_UITSLUITINGEN_VOOR_KLANT';
export const CANCEL_OPEN_ORDER = 'CANCEL_OPEN_ORDER';

// Notification actiontypes
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const DOCUMENTS_GENERATING = 'DOCUMENTS_GENERATING';
export const EXCEL_LINK = 'EXCEL_LINK';
export const CREATE_EXCEL = 'CREATE_EXCEL';
export const SET_USER_TYPE_CONSUMER = 'SET_USER_TYPE_CONSUMER';
export const RESET_EXCEL_DOWNLOAD_LINK = 'RESET_EXCEL_DOWNLOAD_LINK';
export const DISABLE_EXCEL_BUTTON = 'DISABLE_EXCEL_BUTTON';