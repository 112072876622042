import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { userReducer } from './userReducer';
import { siteReducer } from './siteReducer';
import { articleReducer } from './articleReducer';
import { cartReducer } from './cartReducer';
import { notificationReducer } from './notificationReducer';
import { connectRouter } from 'connected-react-router';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    authModule: authReducer,
    userModule: userReducer,
    siteModule: siteReducer,
    articleModule: articleReducer,
    cartModule: cartReducer,
    notificationModule: notificationReducer,
  });
