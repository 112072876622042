import {
  ADD_AUTH,
  REMOVE_AUTH,
  ADMIN_AUTH,
  AUTH_LOADING,
  AUTH_ERROR,
} from '../actions/actionTypes';

const initialState = {
  authentication: null, // authentication data
  token: localStorage.getItem('ELEKTRON_TOKEN')
    ? `Bearer ${localStorage.getItem('ELEKTRON_TOKEN')}`
    : '', // JWT token saved for current authenticated user
  isAuthenticated: false, // flag to check if there is a user authenticated
  adminAuthenticated: false, // flag to check if there is an admin authenticated
  loading: false, // set auth loading state
};

export function authReducer(state = initialState, action) {
  const stateCopy = { ...state };
  switch (action.type) {
    case AUTH_ERROR:
      return { ...stateCopy, error: action.error };
    case AUTH_LOADING:
      return { ...stateCopy, loading: action.loading, error: null };
    case ADD_AUTH:
      return {
        ...stateCopy,
        authentication: action.response.payload,
        token: `Bearer ${action.response.token}`,
        isAuthenticated: true,
        loading: false,
      };
    case REMOVE_AUTH:
      return Object.assign({}, state, {
        authentication: null,
        token: '',
        isAuthenticated: false,
        adminAuthenticated: false,
      });
    case ADMIN_AUTH:
      return Object.assign({}, state, {
        authentication: action.response.payload,
        token: `Bearer ${action.response.token}`,
        isAuthenticated: false,
        adminAuthenticated: true,
      });
    default:
      return state;
  }
}
