import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  MENU_REQ,
  ADD_MENU,
  PAGE_REQ,
  ADD_PAGE,
  CHANGE_VISIBILITY,
  SAVE_CONTENT,
  ADD_NOTIFICATION,
  DELETE_PAGE,
  CREATE_PAGE,
  CHANGE_LANGUAGE,
  GET_FILE_PATH_TO_RESOURCES,
} from '../actions/actionTypes';
import SiteService from '../../services/SiteService';
import i18n from '../../i18n';

export const siteSagas = [
  takeLatest(MENU_REQ, getMenuItems),
  takeLatest(PAGE_REQ, getPages),
  takeLatest(CHANGE_VISIBILITY, updateMenuVisibility),
  takeLatest(SAVE_CONTENT, savePageContent),
  takeLatest(DELETE_PAGE, deletePage),
  takeLatest(CREATE_PAGE, createPage),
  takeLatest(CHANGE_LANGUAGE, changeLanguage),
  takeLatest(GET_FILE_PATH_TO_RESOURCES, getFilePathToResources),
];

function* changeLanguage({ language }) {
  yield
  try {
    localStorage.setItem('ELEKTRON_LNG', language);
    i18n.changeLanguage(language);
    // Kenmerken only has one language, so reload is needed
    const authenticated = yield select((state) => state.authModule.isAuthenticated);
    if(authenticated)
      yield put({ type: "GET_KENMERKEN", payload: {
        filterMerken: [],
        filterCategorien: [],
        filterTypes: [],
        language
      } });
    } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch all the website menu items
 * Save them to the store
 */
function* getMenuItems() {
  try {
    const response = yield call(SiteService.getMenuItems);
    yield put({ type: ADD_MENU, response });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Fetch all the website pages
 * Save them to the store
 * Each page is plain html
 */
function* getPages() {
  try {
    const response = yield call(SiteService.getPages);
    yield put({ type: ADD_PAGE, response });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Update the visibility of a menu item
 * Gets the updated page in return and adds it to the store
 * @param {Object} action
 */
function* updateMenuVisibility(action) {
  try {
    const response = yield call(
      SiteService.updatePageVisibility,
      action.payload,
    );
    yield put({ type: ADD_PAGE, response: response.data });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Update the content of a page (CMS)
 * Adds the updated content to the store
 * Shows notifaction that new content is succesfully saved
 * @param {Object} action
 */
function* savePageContent(action) {
  try {
    const response = yield call(SiteService.savePage, action.payload);
    yield put({ type: ADD_PAGE, response: response.data });
    yield put({
      type: ADD_NOTIFICATION,
      notification: {
        title: 'Pagina opgeslagen',
        message: `Nieuwe content is succesvol opgeslagen`,
        type: 'success',
      },
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Remove a page from the website
 * Removes the page from the store
 * Shows notification that page is succesfully deleted
 * @param {Object} action
 */
function* deletePage(action) {
  try {
    const response = yield call(SiteService.deletePage, action.payload);
    yield put({ type: ADD_PAGE, response: response.data });
    yield put({
      type: ADD_NOTIFICATION,
      notification: {
        title: 'Pagina verwijderd',
        message: `Pagina is succesvol verwijderd`,
        type: 'success',
      },
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Create a new page for the website
 * Add the new page to the store
 * Show notification if content is succesfully added
 * @param {Object} action
 */
function* createPage(action) {
  try {
    const response = yield call(SiteService.createPage, action.payload);
    yield put({ type: ADD_PAGE, response: response.data });
    yield put({
      type: ADD_NOTIFICATION,
      notification: {
        title: 'Pagina toegevoegd',
        message: `Pagina is succesvol toegevoegd`,
        type: 'success',
      },
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Get file paths to img / pdf / logos
 */
function* getFilePathToResources() {
  try {
    const response = yield call(SiteService.getFilePathToResources);
    yield put({ type: "SET_FILE_PATH_TO_RESOURCES", response: response });
  } catch (e) {
    console.log(e);
  }
}
