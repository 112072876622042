import { ADD_MENU, ADD_PAGE, CHANGE_LANGUAGE, RESET_CHECKOUT_FORM_PDF, SET_CHECKOUT_FORM_DATA, SET_CHECKOUT_FORM_PDF, SET_FILE_PATH_TO_RESOURCES } from '../actions/actionTypes';

const initialState = {
  menuItems: null, // array of menu items for the normal website
  pages: null, // array of pages for the normal website
  language: 'nl', // current default language
  resources: {
    landingPage: "/img/taalk_pic.jpg",
    elektronLogo: "/img/elektron_logo.png",
    brandLogosPrefix: "/img/gamma/logo",
    articleImagePrefix: "/img/artikels/",
    articlePDFPrefix: "/docs/",
  }, // path to img's / pdf's / logo's
  checkoutFormData: {
    city: '',
    houseNr: '',
    nameConsumer: '',
    ownReference: '',
    remarksDelivery: '',
    street: '',
    zipcode: '',
  }, // Data from forms on checkout
  checkoutPrintOrderPDF: false,
};

export function siteReducer(state = initialState, action) {
  const stateCopy = { ...state };
  switch (action.type) {
    case ADD_MENU:
      return Object.assign({}, state, {
        menuItems: action.response,
      });
    case ADD_PAGE:
      if (action.response.length === undefined  ) {
        let pages = stateCopy.pages;
        let indexInPages = 0;
        pages.forEach((page, index) => {
          if (page.id === action.response.id) {
            indexInPages = index;
          }
        })
        pages[indexInPages] = action.response;

        return Object.assign({}, state, {
          pages: pages,
        });
      }
      return Object.assign({}, state, {
        pages: action.response,
      });
    case CHANGE_LANGUAGE:
      return { ...stateCopy, language: action.language };
    case SET_FILE_PATH_TO_RESOURCES:
      return { ...stateCopy, resources: action.response };
    case SET_CHECKOUT_FORM_DATA:
      let checkoutFormDataCopy = stateCopy.checkoutFormData;
      if(action.payload.city !== undefined) {
        checkoutFormDataCopy.city = action.payload.city;
      }
      if(action.payload.houseNr !== undefined) {
        checkoutFormDataCopy.houseNr = action.payload.houseNr;
      }
      if(action.payload.nameConsumer !== undefined) {
        checkoutFormDataCopy.nameConsumer = action.payload.nameConsumer;
      }
      if(action.payload.ownReference !== undefined) {
        checkoutFormDataCopy.ownReference = action.payload.ownReference;
      }
      if(action.payload.street !== undefined) {
        checkoutFormDataCopy.street = action.payload.street;
      }
      if(action.payload.remarksDelivery !== undefined) {
        checkoutFormDataCopy.remarksDelivery = action.payload.remarksDelivery;
      }
      if(action.payload.zipcode !== undefined) {
        checkoutFormDataCopy.zipcode = action.payload.zipcode;
      }
      return { 
        ...stateCopy,
        checkoutFormDataCopy,
      };
    case SET_CHECKOUT_FORM_PDF:
      return {
        ...stateCopy, 
        checkoutPrintOrderPDF:  ( action.payload ? (action.payload === true) : (!stateCopy.checkoutPrintOrderPDF) ),
      };
    case RESET_CHECKOUT_FORM_PDF:
      return {
        ...stateCopy, 
        checkoutPrintOrderPDF: false,
      };
    default:
      return state;
  }
}
