import {
  ADD_USER,
  REMOVE_USER,
  ADD_INFO,
  ADD_HERSTELLING,
  ADD_BACKORDER,
  ADD_ACTIES,
  ADD_ART_PROMO,
  ADD_SORTED_ART_PROMO,
  ART_PROMO_ERROR,
  ART_PROMO_LOADING,
  EXCEL_LINK,
  ACTIONS_LOADING,
  RESET_EXCEL_DOWNLOAD_LINK,
  DISABLE_EXCEL_BUTTON,
  SET_USER_TYPE_CONSUMER,
  DOCUMENTS_GENERATING,
  SELECTED_CONSUMERACTIES,
} from '../actions/actionTypes';

const initialState = {
  userInfo: null, // user data that is used on the WebShopInfo page
  klantkorting: null, // discount info for the current user
  merkkorting: null, // discount info for vendors for the current user
  voorwaarden: null, // general & sales conditions to use the webshop
  herstellingen: null, // repairs for the current user
  backorders: null, // backorders for the current user
  acties: null, // actions
  actiesLoading: false, // acties loading
  actiesTotalCount: 0, // totaal aantal acties
  artikelpromoties: null, // articles that have promos for the current user (used on webshop homepage)
  articlesLoading: false,
  articlePromosReLoaded: false,
  articlesError: false,
  sortedpromotions: null,
  disableExcelButton: false, // Disabling create excel button while in process 
  usertypeconsumer: false, // set user type to consumer shows different prices
  generatingDocs: false, // set a loading/blocking icon/btn for generating docs
  selectedArticleActions: null,  // selected article consumer actions based on article id
};

export function userReducer(state = initialState, action) {
  const stateCopy = { ...state };
  switch (action.type) {
    case ADD_SORTED_ART_PROMO:
      return { ...stateCopy, sortedpromotions: action.payload };
    case ART_PROMO_ERROR:
      return { ...stateCopy, articlesError: true, articlesLoading: false };
    case ART_PROMO_LOADING:
      return { ...stateCopy, articlesError: false, articlesLoading: true };
    case ADD_USER:
      return Object.assign({}, state, {
        userInfo: action.response.userInfo,
        klantkorting: action.response.klantkorting,
        merkkorting: action.response.merkkorting,
      });
    case REMOVE_USER:
      return Object.assign({}, state, initialState);
    case ADD_INFO:
      const algemeen = action.response.filter((x) => x.menu_id === 9)[0];
      const verkoop = action.response.filter((x) => x.menu_id === 10)[0];
      return Object.assign({}, state, {
        voorwaarden: { algemeen: algemeen, verkoop: verkoop },
      });
    case ADD_HERSTELLING:
      return Object.assign({}, state, {
        herstellingen: action.response,
      });
    case ADD_BACKORDER:
      return Object.assign({}, state, {
        backorders: action.response,
      });
    case ADD_ACTIES:
      return Object.assign({}, state, {
        acties: action.response?action.response.acties:null,
        actiesTotalCount: action.response?action.response.total:0,
      });
    case ACTIONS_LOADING:
      return Object.assign({}, state, {
        actiesLoading: action.response,
      });
    case ADD_ART_PROMO:
      return {
        ...stateCopy,
        artikelpromoties: action.response,
        articlesLoading: false,
        articlesError: false,
        articlePromosReLoaded: true,
      };
      case EXCEL_LINK:
        return {
          ...stateCopy,
          excelDownloadLink: action.payload.urlpath,
          downloadtimestamp: action.payload.downloadtimestamp,
        };
      case RESET_EXCEL_DOWNLOAD_LINK:
        return {
          ...stateCopy,
          excelDownloadLink: "",
          downloadtimestamp: "",
        };
      case DISABLE_EXCEL_BUTTON:
        return {
          ...stateCopy,
          disableExcelButton: action.payload,
        };
      case SET_USER_TYPE_CONSUMER:
        return {
          ...stateCopy,
          usertypeconsumer: action.payload,
        };
      case DOCUMENTS_GENERATING:
        return {
          ...stateCopy,
          generatingDocs: action.status,
        };
      case SELECTED_CONSUMERACTIES:
        return {
          ...stateCopy,
          selectedArticleActions: action.response
        }
    default:
      return state;
  }
}
