import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/actionTypes';
import { v4 } from 'uuid';

const initialState = {
  notifications: [], // array containing all notifications
};

export function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      action.notification.id = v4();
      return Object.assign({}, state, {
        notifications: [...state.notifications, action.notification],
      });
    case REMOVE_NOTIFICATION:
      return Object.assign({}, state, {
        notifications: [
          ...state.notifications.filter((x) => x.id !== action.id),
        ],
      });
    default:
      return state;
  }
}
