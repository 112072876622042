import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { equalsValue, formatCartArticle, calculateLineNr } from '../../helpers/cartHelper';
import ArticleService from '../../services/ArticleService';
import CartService from '../../services/CartService';
import {
  ADD_CART,
  ADD_ITEM,
  CLEAR_CART,
  COMPLETE_ORDER,
  COMPLETE_ORDER_CONSUMER,
  EMPTY_CART,
  GET_CURRENT_CART,
  GET_UITSLUITINGEN_VOOR_KLANT,
  REMOVE_CART,
  REMOVE_ITEM,
  SET_CART,
  UPDATE_CART,
  UPDATE_ITEM,
  CANCEL_OPEN_ORDER,
} from '../actions/actionTypes';

export const cartSagas = [
  takeLatest(ADD_CART, addItemToCart),
  takeLatest(REMOVE_CART, removeItemFromCart),
  takeLatest(CLEAR_CART, clearCart),
  takeLatest(GET_CURRENT_CART, getCurrentCart),
  takeLatest(COMPLETE_ORDER, completeOrder),
  takeLatest(COMPLETE_ORDER_CONSUMER, completeOrderConsumer),
  takeLatest(CANCEL_OPEN_ORDER, cancelOpenOrder),
  takeLatest(GET_UITSLUITINGEN_VOOR_KLANT, getUitsluitingenVoorKlant),
  takeLatest(UPDATE_CART, doReSetCartWithNewState),
];

/**
 * Add item to cart and update the store
 * @param {Object} payload containing {artikel, amount, amount2, amount3, newPrice(opt), free(opt),added(opt),linenr(opt),weborderID(opt),LOCAL_ONLY(opt)}
 */
function* addItemToCart({ payload }) {
  //console.log("Add Item To Cart - PAYLOAD = ", payload, " - add locally only ? " , (payload.LOCAL_ONLY ? "ONLY local cart adjusted" : "SUBMITTING new item"));
  try {
    if(!payload|| Object.keys(payload).length<1) {  
      console.error("Empty payload : ", payload);
      return;
    }
    
    const language = yield select((state) => state.siteModule.language);
    const currentCart = yield select((state) => state.cartModule.cart);
    
    const LOCAL_ONLY = payload.LOCAL_ONLY || false;
    const {
      amount: hoeveelheid1,
      amount2: hoeveelheid2,
      amount3: hoeveelheid3,
      newPrice: nieuwePrijs,
      free: isGratis,
      added: extraAdded,
      linenr,
      weborderID,
      newlyAdded,
    } = payload;
    let artikel = payload.artikel ;

    // get max linenr...and calculate new one (if undefined)
    const currentNewLineNr = calculateLineNr(currentCart, linenr, LOCAL_ONLY);
    let newlyAddedArticleFlag = newlyAdded;
    if(linenr > 0 && currentNewLineNr !== linenr){
      newlyAddedArticleFlag = true;
    }

    // if artikel is only the ID -> look it up first !
    if(artikel instanceof String || typeof(artikel) === 'string'){
      //console.log("Retrieving full article details for ID = ",artikel,"...");
      let fullArtikel = yield call(ArticleService.getArticleDetail,artikel,language);
      artikel = fullArtikel.artikel[0]; // replace articleID by full article details...
    }
    // assemble data
    const data = {
      hoeveelheid1: hoeveelheid1 ? parseInt(hoeveelheid1) : 0,
      hoeveelheid2: hoeveelheid2 ? parseInt(hoeveelheid2) : 0,
      hoeveelheid3: hoeveelheid3 ? parseInt(hoeveelheid3) : 0,
      id: artikel.artikelID,
      prijs: nieuwePrijs || artikel.verkoopprijs,
      free: isGratis || false,
        added: extraAdded || 0,
      linenr : linenr || -1 , // TODO: checkit currentNewLineNr,
      localonly: (LOCAL_ONLY===true),
      newlyAdded: newlyAddedArticleFlag,
    };

    if(!LOCAL_ONLY){
      // submit this to backend and database
      yield all([
        call(CartService.addItemToCart, data),
        call(updateCartState, {
          artikel,
          hoeveelheid1: data.hoeveelheid1, 
          hoeveelheid2: data.hoeveelheid2,
          hoeveelheid3: data.hoeveelheid3,
          added: data.added,
          free: data.free,
          linenr: data.linenr,
          localonly: (LOCAL_ONLY===true),
          newlyAdded: newlyAddedArticleFlag,
        }),
      ]);
    }else{
      // only push item to the cart list (do NOT update database or cart in backend !!)
      const item = formatCartArticle({ artikel, hoeveelheid1, hoeveelheid2, hoeveelheid3, free:(isGratis||false), added: (extraAdded ||0), linenr, weborderID, newlyAdded: newlyAddedArticleFlag });
      yield put({ type: ADD_ITEM, item });
    }

  } catch (e) {
    console.error("Error in cartsaga.addItemToCart():",e);
    console.trace("payload: ", payload);
  }
}

/**
 * Update cart state
 * Can add new item or update existing item
 * @param {Object, Number} { artikel, hoeveelheid1 }
 */
function* updateCartState({ artikel, hoeveelheid1=0, hoeveelheid2=0, hoeveelheid3=0, added=false, free=false, linenr=0, newlyAdded=false }) {
  try {
    const item = formatCartArticle({ artikel, hoeveelheid1, hoeveelheid2, hoeveelheid3, free, added, linenr});
    const currentCart = yield select((state) => state.cartModule.cart);
    let currentItem = currentCart.find(
      (x) => (x.artikel.artikelID === item.artikel.artikelID) && !x.free ,
    );
    if (currentItem) {
      currentItem = {
        ...currentItem,
        hoeveelheid1:
          parseInt(currentItem.hoeveelheid1) + parseInt(hoeveelheid1),
        hoeveelheid2:
          parseInt(currentItem.hoeveelheid2) + parseInt(hoeveelheid2),
        hoeveelheid3:
          parseInt(currentItem.hoeveelheid3) + parseInt(hoeveelheid3),
      };
      yield put({ type: UPDATE_ITEM, item: currentItem });
    } else {
      yield put({ type: ADD_ITEM, item });
    }
  } catch (e) {
    console.error("Error in cartsaga.updateCartState():",e);
    console.trace("Payload:", { artikel, hoeveelheid1, hoeveelheid2, hoeveelheid3, added, free, linenr});
  }
}

/**
 * Remove item from cart and update store
 * @param {Object} id
 */
function* removeItemFromCart({ id }) {
  try {
    yield put({ type: REMOVE_ITEM, id });
    yield call(CartService.removeItemFromCart, id);
    
    // If last item is cleared from cart, verwijderWebOrder must be called (see OEL-190)
    const currentCart = yield select((state) => state.cartModule.cart);
    if(currentCart.length === 0) {
      //console.log('Last item of cart deleted, deleting webOrder');
      yield call(clearCart); // this is not the case ?
    }
  } catch (e) {
    console.error("Error in cartsaga.removeItemFromCart("+id+"):",e);
  }
}

/**
 * Complete the order
 */
function* completeOrder() {
  try {
    yield put({ type: EMPTY_CART });
    yield put(push('/webshop/nieuws'));
  } catch (e) {
    console.error("Error in cartsaga.completeOrder():",e);
  }
}

/**
 * Complete the order for consumer
 */
function* completeOrderConsumer(payload) {
  try {
    ArticleService.completeOrderForConsumers(payload);
    yield put({ type: EMPTY_CART });
    // yield put(push('/1'));
  } catch (e) {
    console.error("Error in cartsaga.completeOrderConsumer():",e);
    console.trace("Payload:",payload);
  }
}

/**
 * Cancel any open order for consumer.
 * @param {*} payload consists of  {klantId and orderId}
 */
function* cancelOpenOrder({payload}) {
  try {
    if(!payload || !payload.klantId || !payload.orderId) throw Error("Either expected klantId and orderId as payload values. Only got "+ JSON.stringify(payload));

    let klantId = payload.klantId;
    let orderId = payload.orderId;

    // cancel OPEN order...
    ArticleService.cancelOpenOrderForConsumer(klantId);
    // delete any unconfirmed orders
    CartService.verwijderWebOrder(orderId);
    
    yield put({ type: EMPTY_CART });
    // yield put(push('/1'));
  } catch (e) {
    console.error("Error in cartsaga.cancelOpenOrderForConsumer():",e);
    console.trace("Payload:",payload);
  }
}


/**
 * Empty the cart
 */
function* clearCart() {
  try {
    const currentCart = yield select((state) => state.cartModule.cart);

    for(let i = 0; i < currentCart.length; i++) {
      yield call(removeItemFromCart, { id: currentCart[i].artikel.artikelID } )
    }

    // nota: denk dat die niet meer gebruikt wordt, omdat een weborder blijft open staan
    // tot dat deze voltooid wordt ...

    // verwijderWebOrder is called in removeItemFromCart() if it finds the cart empty.
    // yield call(verwijderWebOrder);
    // yield put({ type: EMPTY_CART});
  } catch (e) {
    console.error("Error in cartsaga.clearCart():",e);
  }
}

/**
 * Call verwijderweborder
 */
// function* verwijderWebOrder() {
//   try {
//     const resVerwijderweborder = yield call(CartService.verwijderWebOrder);
//     console.log('res verwijderweborder =', resVerwijderweborder)
//     if(resVerwijderweborder.status && resVerwijderweborder.status === 200) {
//       console.log('status 200')
//      // yield put(push('/webshop/artikels'));
//     }
//   } catch (e) {
//     console.log(e);
//   }
// }

/**
 * Filter out articles not allowed to purchase
 */
function* getUitsluitingenVoorKlant() {
  try {
    const currentCart = yield select((state) => state.cartModule.cart);
    const listUitsluitingen = yield call(CartService.getUitsluitingenVoorKlant);

    let listOfArticlesInCartButNotAllowed = [];
    if(listUitsluitingen && listUitsluitingen.data && listUitsluitingen.data.length > 0) {
      currentCart.forEach(artikel => {
        if(listUitsluitingen.data.includes(artikel.artikel.artikelID)) {
          listOfArticlesInCartButNotAllowed.push(artikel.artikel.artikelID);
        }
      })
    }
    // Delete items from cart
    listOfArticlesInCartButNotAllowed.forEach(function* (artikel) {
      // Back end
      yield call(CartService.removeItemFromCart, artikel);
      // Front end
      const payload = { id: artikel}
      yield call(removeItemFromCart, payload);
    })
  } catch (e) {
    console.log(e);
  }
}

/**
 * Get the current cart from backend 
 */
function* getCurrentCart() {
  try{
    const currentCart = yield select(state=> state.cartModule.cart);
    const databaseCart = yield call(CartService.getCurrentCart);

    let hasDifferences = false;
    if(databaseCart && databaseCart.length>0 && currentCart && currentCart.length>0){
      databaseCart.forEach(element => {
        let match = currentCart.find(x => equalsValue(x.artikel.artikelID, element.produktID));
        if(!match) hasDifferences = true;

      });
    }

    if(hasDifferences){}
      let newCart = databaseCart.map((x) => formatCartArticle(x));
      yield put({ type: SET_CART, cart: newCart });

  }catch(err){
    console.error("Failed to getCurrentCart() : ",err);
  }


}

function* doReSetCartWithNewState({ payload }) {
  try{
    // loop over each item, and replace non-item resolved articles...
    
    if(payload && payload.length > 0){
      
      let newCart = [];
      const language = yield select((state) => state.siteModule.language);

      for(let cartItem of payload){
        // const {
        //   amount: hoeveelheid1,
        //   amount2: hoeveelheid2,
        //   amount3: hoeveelheid3,
        //   newPrice: nieuwePrijs,
        //   free: isGratis,
        //   added: extraAdded,
        //   linenr,
        //   weborderID,
        //   newlyAdded,
        // } = cartItem;
        let artikel = cartItem.artikel ;
        
        if(artikel instanceof String || typeof(artikel) === 'string'){
          //console.log("Retrieving full article details for ID = ",artikel,"...");
          let fullArtikel = yield call(ArticleService.getArticleDetail,artikel,language);
          artikel = fullArtikel.artikel[0]; // replace articleID by full article details...
        }

        cartItem.artikel = artikel;

        // add...
        newCart.push(cartItem);
      };

      yield put({ type: SET_CART, cart: newCart });
    }

  }catch(Exception){
    console.log("Exception in cartSaga : ",Exception);
  }
}