import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  ARTICLE_REQ,
  ADD_ARTICLE,
  PROMOS_REQ,
  PROMOS_ADD,
  ARTICLES_LOADING,
  PROMOS_LOADING,
} from '../actions/actionTypes';
import ArticleService from '../../services/ArticleService';
import CartService from '../../services/CartService';
import i18n from '../../i18n';

export const articleSagas = [
  takeLatest(ARTICLE_REQ, getArtikels),
  takeLatest(PROMOS_REQ, getPromos),
  takeLatest('ARTICLE_DETAIL', getArticleDetail),
  takeLatest('GET_KENMERKEN', getKenmerken),
];

function* getArticleDetail({ payload }) {
  try {
    const response = yield call(
      ArticleService.getArticleDetail,
      payload,
      i18n.language,
    );

    yield put({ type: 'ARTICLE_MODAL_DETAIL', payload: response });
    yield put({ type: 'SHOW_ARTICLE_MODAL', show: true });
  } catch (e) {
    console.error("ERROR getArticleDetail :",e);
  }
}

/**
 * Fetch all articles and put them in store
 * @param {Object} params
 */
function* getArtikels(params) {
  const { page } = params;
  yield put({ type: ARTICLES_LOADING, status:true });
  try {
    const language = yield select(state => state.siteModule.language)
    params.language = language;
    const response = yield call(ArticleService.getFullArticles, params);
    yield put({ type: ADD_ARTICLE, response, page });
    
    // Storing the data for checkboxes in filter.js || filtering kenmerken in OEL-132
    // console.log('params for getArticles = ', params)
    yield put({ type: "SET_PARAMS_FOR_GET_ARTICLES", params  });

    // Store uitsluitingen for klant to determine whether or not 'Purchase' btn on related article can be shown.
    let listUitsluitingen = yield call(CartService.getUitsluitingenVoorKlant);
    if (listUitsluitingen && listUitsluitingen.data) {
      listUitsluitingen = listUitsluitingen.data;
    } else {
      listUitsluitingen = [];
    }
    yield put({ type: "STORE_UITSLUITINGEN", listUitsluitingen });        
  } catch (e) {
    console.error("Error getArtikels:",e);
  }
  yield put({ type: ARTICLES_LOADING, status:false });
}

/**
 * Fetch promotions and put them in store
 */
function* getPromos() {
  try {
    yield put({ type: PROMOS_LOADING, status:true });
    const response = yield call(ArticleService.getPromos);
    yield put({ type: PROMOS_ADD, response });
  } catch (e) {
    console.error("Error getPromos:",e);
  }
  yield put({ type: PROMOS_LOADING, status:false });
}

/**
 * Fetch kenmerken for filtering articles
 */
function* getKenmerken(payload) {
  try {
    const parameters = yield select((state) => state.articleModule.params);
    const params = {
      merken: payload.payload.filterMerken,
      categorien: payload.payload.filterCategorien,
      types: payload.payload.filterTypes, 
      language: i18n.language,
      filterKenmerken: payload.payload.filterKenmerken,
      parameters: parameters,
    }
    
    let kenmerken = yield call(ArticleService.getKenmerken, params);
    const sortedKenmerken = sortKenmerken(kenmerken);
    yield put({ type: 'SET_KENMERKEN', payload: sortedKenmerken });
  } catch (e) {
    console.log(e);
  }
}

const sortKenmerken = (arr) => {

  var a = arr;
  var numericPart;
  var letterPart;
  var max_chars = 4;
  var pad_char = 0;
  var b = [];
  for (var i=0; i<a.length; i++) {
      numericPart = a[i].kenmerk.match(/\d+/);
      numericPart = (new Array(max_chars + 1).join(pad_char) + numericPart).slice(-max_chars); 
      letterPart = a[i].kenmerk.match(/[A-z]+/);
      b[i] = {};
      b[i].kenmerk = letterPart+numericPart;
      
      b[i].kenmerk_code = a[i].kenmerk_code;
      b[i].subcategorie = a[i].subcategorie;
      b[i].subcategorie_code = a[i].subcategorie_code;
  }
  b.sort(function(a,b){
      if (a.kenmerk > b.kenmerk)
          return 1;
      if (a.kenmerk < b.kenmerk)
          return -1;
      // a must be equal to b
      return 0;
  });

  b.forEach(b => {
    let label = ''
      arr.forEach(original => {
        if(b.kenmerk_code === original.kenmerk_code) {
          label = original.kenmerk
        }
      })
      b.kenmerk = label;
  })
  arr = b;
  
  const obj = {};
  arr.forEach((item) => {
    if (obj[item.subcategorie]) {
      obj[item.subcategorie].push(item);
    } else {
      obj[item.subcategorie] = [item];
    }
  });
  return obj;
};
