import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Search articles based on given params
 * @param {Object} params
 * @return {Array} articles
 */
const getFullArticles = (params) => {
  if (params.kenmerken && !Object.keys(params.kenmerken).length > 0)
    delete params.kenmerken;

  return axios.get(`${API_URL}/artikels`, { params }).then((res) => {
    return res.data;
  });
};

/**
 * Get detailed article info about one specific article
 * Language needs to be passed to get translated data back
 * @param {String} id
 * @param {String} lng
 * @return {Object} article detail
 */
const getArticleDetail = (id, lng) => {
  id = id.replace('/', 'forwardslash')
  return axios
    .get(`${API_URL}/artikeldetail/${id}`, { params: { id, lng } })
    .then((res) => {
      return res.data;
    });
};

/**
 * Get the vendors that have articles that fit the passed params
 * Is used to fetch available vendors for the article filter
 * @param {Array} categorien
 * @param {Array} types
 * @param {Object} kenmerken
 * @return {Array} merken
 */
const getMerken = (categorien, types, kenmerken) => {
  const params = {
    categorien,
    types,
    kenmerken: Object.keys(kenmerken).length > 0 ? kenmerken : null,
  };

  return axios.get(`${API_URL}/merken`, { params }).then((res) => {
    return res.data;
  });
};

/**
 * Get the categories that have articles that fit the passed params
 * Is used to fetch available categories for the article filter
 * @param {Array} merken
 * @param {Array} types
 * @param {Object} kenmerken
 * @return {Array} categorien
 */
const getCategorien = (merken, types, kenmerken) => {
  const params = {
    merken,
    types,
    kenmerken: Object.keys(kenmerken).length > 0 ? kenmerken : null,
  };

  return axios.get(`${API_URL}/categorien`, { params }).then((res) => {
    return res.data;
  });
};

/**
 * Get the types that have articles that fit the passed params
 * Is used to fetch available types for the article filter
 * @param {Array} categorien
 * @param {Array} merken
 * @return {Array} types
 */
const getTypes = (merken, categorien) => {
  return axios
    .get(`${API_URL}/types`, { params: { merken, categorien } })
    .then((res) => {
      return res.data;
    });
};

/**
 * Get the attributes that have articles that fit the passed params
 * Is used to fetch available types for the article filter
 * @param {Array} merken
 * @param {Array} categorien
 * @param {Array} types
 * @return {Object} kenmerken
 */
const getKenmerken = ({merken, categorien, types, language, filterKenmerken, parameters }) => {
  // console.log('servicelaag getKenmerken = ', parameters)
  const lng = language === 'nl' ? 'NL' : 'FR';
  return axios
    .get(`${API_URL}/kenmerken`, {
      params: { merken, categorien, types, lng, filterKenmerken, parameters },
    })
    .then((res) => {
      return res.data;
    });
};

/**
 * Get the current promos
 * @return {Array} promos
 */
const getPromos = () => {
  //console.log('ArticleService getPromos')
  return axios.get(`${API_URL}/elpcdb`).then((res) => {
    return res.data;
  });
};

/**
 * Complete the current order
 * @param {Object} payload
 */
const completeOrder = (payload) => {
  return axios.post(`${API_URL}/order`, payload).then((res) => {
    return res;
  });
};

/**
 * Complete the current order in consumermode
 * @param {Object} payload
 */
const completeOrderForConsumers = (payload) => {
  return axios.post(`${API_URL}/orderconsumer`, payload).then((res) => {
    return res;
  });
};

/**
 * Complete the current order in consumermode
 * @param {String} klantId
 */
const cancelOpenOrderForConsumer = (klantId) => {
  console.log("Cancelling Open Order for klantID", klantId);
  return axios.delete(`${API_URL}/orderconsumer?customerid=${klantId}`).then((res) => {
    return res;
  });
};


/**
 * Check the current order for any promos that can be applied
 * @return {?} ?
 */
const checkForPromos = (payload) => {
  return axios.get(`${API_URL}/checkorder`, { params: {payload}}).then((res) => {
    return res;
  });
};

/**
 * Create csv and call createnewweborder (used in consumermode)
 * @return {?} ?
 */
const createCSVAndNewWebOrderForConsumer = (payload) => {
  return axios.get(`${API_URL}/createcsvandnewweborderforconsumer`, { params: {payload}}).then((res) => {
    return res;
  });
};

export default {
  getFullArticles,
  getArticleDetail,
  getMerken,
  getCategorien,
  getTypes,
  getKenmerken,
  completeOrder,
  checkForPromos,
  getPromos,
  completeOrderForConsumers,
  cancelOpenOrderForConsumer,
  createCSVAndNewWebOrderForConsumer,
};
