import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Fetch the user data of the current logged in user.
 * Contains info like discounts
 * @param {Object} userInfo !Should be checked, is not used?
 * @return {Object} userInfo
 */
const getUserInfo = (userInfo) => {
  return axios.get(`${API_URL}/me`, userInfo).then((res) => {
    const { userInfo, klantkorting, merkkorting } = res.data;
    return { userInfo, klantkorting, merkkorting };
  });
};

/**
 * Fetch the 'General & sale conditions' of the webshop
 * @return {Array} conditions
 */
const getVoorwaarden = () => {
  return axios.get(`${API_URL}/voorwaarden`).then((res) => {
    return res.data;
  });
};

/**
 * Fetch the repairs for the current user
 * @return {Array} herstelligen
 */
const getHerstellingen = () => {
  return axios.get(`${API_URL}/herstellingen`).then((res) => {
    return res.data;
  });
};

/**
 * Fetch the backorders for the current user
 * @return {Array} backorders
 */
const getBackorders = () => {
  return axios.get(`${API_URL}/backorders`).then((res) => {
    return res.data;
  });
};

/**
 * Fetch the current promotional actions
 * Each action contains article data and a PDF with the action info
 * @return {Array} actions
 */
const getConsumentenActies = (limit=50,page=0) => {
  return axios.get(`${API_URL}/acties?size=${limit}&page=${page}`).then((res) => {
    return res.data;
  });
};

/**
 * Fetch the current promotional actions
 * Each action contains article data and a PDF with the action info
 * @return Action or not...
 */
const getConsumentenActiesForArticle = (articleID) => {
  const articleIDEsc = articleID.replace('/', 'forwardslash')
  return axios.get(`${API_URL}/acties/${articleIDEsc}`).then((res) => {
    return res.data;
  });
};

/**
 * Fetch all the articles that have promotions
 * These articles are used to show on the frontpage of the webshop
 * @param {Boolean} reload optional full reload from remote (def=false)
 * @return {Array} articles
 */
const getArtikelPromoties = (reload=false) => {
  return axios.get(`${API_URL}/artikelpromoties?reload=${reload}`).then(
    (res) => {
      return res.data;
    },
    (err) => {
      throw err;
    },
  );
};


/**
 * Create excelfile
 * @return {Array} menu items
 */
const createExcel = (payload) => {
  //console.log("Requesting XLS generation : ", payload);
  return axios.post(`${API_URL}/createexcel`, payload).then(
    (res) => {
      if (res.data.status === true) {
        //console.log("XLS Export response : ", res.data);
        return { urlpath: res.data.urlpath , downloadtimestamp: res.data.generatortimestamp };
      }
      return null;
    },
    (err) => {
      console.error("Error XLS create : ", err);
      throw err;
    }
  );
};


/**
 * Get image from web
 * * @return {Array} menu items
 */
const getRemoteImage = (payload) => {
  try{
    console.log(`Trying to retrieve Remote Image for ${payload} on remote server ${process.env.REACT_APP_API_URL} ...`);
    return axios.get(`${process.env.REACT_APP_API_URL}/remoteimage?url=${payload}`).then(response => {
    return response.data.base64;
  });
  } catch(e) {
    console.error("ERROR: Failed to get getRemoteImage : " ,e);
    return "";
  }  
};


export default {
  getRemoteImage,
  createExcel,
  getUserInfo,
  getVoorwaarden,
  getHerstellingen,
  getBackorders,
  getConsumentenActies,
  getArtikelPromoties,
  getConsumentenActiesForArticle,
};
